<ngrx-busy>
  <form [formGroup]="form"
        (ngSubmit)="save()"
        class="side-form">

    <h3>Update promo code</h3>

    <mat-form-field appearance="fill">
      <mat-label>Code</mat-label>
      <input matInput
             formControlName="code">
      <mat-error *ngIf="form.get('code').hasError('required')"><strong>required</strong></mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Trial days</mat-label>
      <input matInput
             formControlName="trial_days"
             required
             [imask]="{mask: Number, scale: 0}">
      <mat-error *ngIf="form.get('trial_days').hasError('required')"><strong>required</strong></mat-error>
      <mat-error *ngIf="form.get('trial_days').hasError('min')"><strong>min value is 1</strong></mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <input matInput
             formControlName="linked_to"
             [matAutocomplete]="auto">
      <mat-autocomplete #auto="matAutocomplete"
                        [displayWith]="displayFn">
        <mat-option *ngFor="let option of customers$ | async"
                    [value]="option">
          {{option.full_name}} ({{option.email}})
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Usage limit</mat-label>
      <input matInput
             formControlName="usage_limit"
             required
             [imask]="{mask: Number, scale: 0}">
      <mat-error *ngIf="form.get('usage_limit').hasError('required')"><strong>required</strong></mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline"
                    filter-control>
      <mat-label>valid_until</mat-label>

      <mtx-datetimepicker #validUntil
                          type="datetime"
                          mode="portrait"
                          startView="clock"
                          [twelvehour]="false"
                          [timeInput]="true"></mtx-datetimepicker>
      <input matInput
             [mtxDatetimepicker]="validUntil"
             formControlName="valid_until">
      <mtx-datetimepicker-toggle matSuffix
                                 [for]="validUntil"></mtx-datetimepicker-toggle>
      <mat-error *ngIf="form.get('valid_until').hasError('required')"><strong>required</strong></mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Note</mat-label>
      <textarea matInput
                rows="3"
                formControlName="note">
      </textarea>
    </mat-form-field>

    <mat-checkbox formControlName="active">Active</mat-checkbox>

    <div class="side-form-actions">
      <button mat-raised-button
              type="submit"
              color="primary"
              [disabled]="form.invalid">
        Update
      </button>
    </div>

    <app-form-error></app-form-error>
  </form>
</ngrx-busy>
