<app-legend #legend
            style="display: none">
  <app-legend-item [key]="true"
                   color="green">Active
  </app-legend-item>
  <app-legend-item [key]="false">Not active
  </app-legend-item>
</app-legend>

<mat-card>
  <app-headline>
    <h1 app-headline-title>Subscription plans</h1>
  </app-headline>

  <ngrx-busy>
    <div class="overflow-content">
      <table mat-table
             [dataSource]="plans$ | async">
        <ng-container matColumnDef="price">
          <th mat-header-cell
              *matHeaderCellDef>Name
          </th>
          <td mat-cell
              *matCellDef="let row">
            {{getDescriptionName(row)}} <code *ngIf="!row.active"
                                              class="archived">Archived</code>
            <br>
            {{row.price / 100}} USD /
            <ng-container *ngIf="row.interval_count > 1;then multipleInterval else singleInterval"></ng-container>
            <ng-template #multipleInterval>{{row.interval_count}} {{row.interval}}s</ng-template>
            <ng-template #singleInterval>{{row.interval}}</ng-template>
          </td>
        </ng-container>
        <ng-container matColumnDef="trial">
          <th mat-header-cell
              *matHeaderCellDef>Trial period days
          </th>
          <td mat-cell
              *matCellDef="let row">
            {{row.trial}}
          </td>
        </ng-container>
        <ng-container matColumnDef="id">
          <th mat-header-cell
              *matHeaderCellDef>Plan id
          </th>
          <td mat-cell
              *matCellDef="let row"
              class="min-width">
            <app-code appearance="text">{{row.id}}</app-code>
          </td>
        </ng-container>
        <ng-container matColumnDef="created">
          <th mat-header-cell
              *matHeaderCellDef>Created
          </th>
          <td mat-cell
              *matCellDef="let row"
              class="min-width">{{row.created | utc | moment:'medium'}}</td>
        </ng-container>
        <ng-container matColumnDef="options">
          <th mat-header-cell
              *matHeaderCellDef>
          </th>
          <td mat-cell
              *matCellDef="let row"
              class="min-width">
            <button mat-icon-button
                    *ngIf="row.active"
                    (click)="editPlan(row)">
              <mat-icon>edit</mat-icon>
            </button>
          </td>
        </ng-container>
        <tr mat-header-row
            *matHeaderRowDef="columns"></tr>
        <tr mat-row
            [legendRow]="legend"
            [legendRowValue]="row.active"
            *matRowDef="let row; columns: columns"></tr>
      </table>
    </div>
  </ngrx-busy>
</mat-card>

