import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {NgrxBusy, withBusy} from 'ngrx-busy';
import {firstValueFrom, Observable} from 'rxjs';
import {
  Filter,
  GetPromoCodes,
  PromoCode,
  SideNav,
  SubscriptionsClient
} from 'shared';
import {PromoCodeFormComponent} from './promo-code-form/promo-code-form.component';

@Component({
  selector: 'app-promo-codes',
  templateUrl: './promo-codes.component.html',
  styleUrls: ['./promo-codes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PromoCodesComponent {

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(Filter, {static: true}) filter: Filter;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(NgrxBusy, {static: true}) busy: NgrxBusy;

  columns = ['code', 'trial_days', 'linked_to', 'usage_limit', 'applies', 'note', 'valid_until', 'created_at', 'options'];
  promoCodes$: Observable<PromoCode[]>;

  constructor(
    public subscriptionsClient: SubscriptionsClient,
    private readonly sidenav: SideNav,
    private cdr: ChangeDetectorRef) {
    this.promoCodes$ = this.subscriptionsClient.getPromoCodes(new GetPromoCodes()).pipe(withBusy(() => this.busy));
  }

  async openForm(code?: PromoCode): Promise<void> {
    const result = await firstValueFrom(this.sidenav.open(PromoCodeFormComponent, {data: code}).beforeClosed());
    if (!result) { return; }

    this.promoCodes$ = this.subscriptionsClient.getPromoCodes(new GetPromoCodes()).pipe(withBusy(() => this.busy));
    this.cdr.detectChanges();
  }
}
