<app-legend #legend
            style="display: none">
  <app-legend-item [key]="true"
                   color="green">Active
  </app-legend-item>
  <app-legend-item [key]="false">Not active
  </app-legend-item>
</app-legend>

<mat-card>
  <app-headline>
    <h1 app-headline-title>Promo codes</h1>
    <app-headline-actions align="left">
      <button mat-icon-button
              (click)="openForm()">
        <mat-icon>add</mat-icon>
      </button>
    </app-headline-actions>
  </app-headline>

  <ngrx-busy>
    <div class="overflow-content">
      <table mat-table
             [dataSource]="promoCodes$ | async">
        <ng-container matColumnDef="code">
          <th mat-header-cell
              *matHeaderCellDef>Code
          </th>
          <td mat-cell
              *matCellDef="let row">
            {{row.code}}
          </td>
        </ng-container>
        <ng-container matColumnDef="trial_days">
          <th mat-header-cell
              *matHeaderCellDef>Trial days
          </th>
          <td mat-cell
              *matCellDef="let row">
            {{row.trial_days}}
          </td>
        </ng-container>
        <ng-container matColumnDef="linked_to">
          <th mat-header-cell
              *matHeaderCellDef>Linked to
          </th>
          <td mat-cell
              *matCellDef="let row"
              class="min-width">
            {{!!row.linked_to ? row.linked_to.full_name : ''}}
          </td>
        </ng-container>
        <ng-container matColumnDef="usage_limit">
          <th mat-header-cell
              *matHeaderCellDef>Usage limit
          </th>
          <td mat-cell
              *matCellDef="let row"
              class="min-width">
            {{row.usage_limit}}
          </td>
        </ng-container>
        <ng-container matColumnDef="applies">
          <th mat-header-cell
              *matHeaderCellDef>Applies
          </th>
          <td mat-cell
              *matCellDef="let row"
              class="min-width">
            {{row.applies}}
          </td>
        </ng-container>
        <ng-container matColumnDef="note">
          <th mat-header-cell
              *matHeaderCellDef>Note
          </th>
          <td mat-cell
              *matCellDef="let row"
              class="min-width">
            {{row.note}}
          </td>
        </ng-container>
        <ng-container matColumnDef="valid_until">
          <th mat-header-cell
              *matHeaderCellDef>Valid until
          </th>
          <td mat-cell
              *matCellDef="let row"
              class="min-width">{{row.valid_until | utc | moment:'medium'}}</td>
        </ng-container>
        <ng-container matColumnDef="created_at">
          <th mat-header-cell
              *matHeaderCellDef>Created at
          </th>
          <td mat-cell
              *matCellDef="let row"
              class="min-width">{{row.created_at | utc | moment:'medium'}}</td>
        </ng-container>
        <ng-container matColumnDef="options">
          <th mat-header-cell
              *matHeaderCellDef>
          </th>
          <td mat-cell
              *matCellDef="let row"
              class="min-width">
            <button mat-icon-button
                    (click)="openForm(row)">
              <mat-icon>edit</mat-icon>
            </button>
          </td>
        </ng-container>
        <tr mat-header-row
            *matHeaderRowDef="columns"></tr>
        <tr mat-row
            [legendRow]="legend"
            [legendRowValue]="row.active"
            *matRowDef="let row; columns: columns"></tr>
      </table>
    </div>
  </ngrx-busy>
</mat-card>

