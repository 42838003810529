import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {SharedModule} from 'shared';
import {AdminUsersComponent} from './admin-users/admin-users.component';
import {AdminUserFormComponent} from './admin-users/admin-user-form/admin-user-form.component';
import {SubscriptionPlansComponent} from './subscription-plans/subscription-plans.component';
import {AdminRolesComponent} from './admin-roles/admin-roles.component';
import {AdminRoleFormComponent} from './admin-roles/admin-role-form/admin-role-form.component';
import {SupportComponent} from "./support.component";
import { SubscriptionPlanFormComponent } from './subscription-plans/subscription-plan-form/subscription-plan-form.component';
import {PromoCodesComponent} from "./promo-codes/promo-codes.component";
import {PromoCodeFormComponent} from "./promo-codes/promo-code-form/promo-code-form.component";

@NgModule({
  declarations: [
    SupportComponent,
    AdminUsersComponent,
    AdminUserFormComponent,
    SubscriptionPlansComponent,
    SubscriptionPlanFormComponent,
    PromoCodesComponent,
    PromoCodeFormComponent,
    AdminRolesComponent,
    AdminRoleFormComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild([
      {
        path: '',
        component: SupportComponent
      },
      // {
      //   path: 'roles',
      //   component: AdminRolesComponent
      // }
    ])
  ]
})
export class SupportModule {
}
