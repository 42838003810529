import {ChangeDetectionStrategy, Component, Input, ViewChild} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {Observable} from "rxjs";
import {distinctUntilChanged, first, map, publishReplay, refCount, switchMap} from 'rxjs/operators';
import {ObservableInput} from "ngx-observable-input";
import * as moment from 'moment';
import {NgrxBusy, withBusy} from "ngrx-busy";
import {SubscriptionsComponent} from "./subscriptions.component";
import {BillingPublicClient, Pet, Subscription} from "../public-clients";

@Component({
  selector: 'app-subscription-card',
  templateUrl: './subscription-card.component.html',
  styleUrls: ['./subscription-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubscriptionCardComponent {

  @ViewChild(NgrxBusy, {static: true}) busy: NgrxBusy;

  @ObservableInput() @Input('pet') pet$: Observable<Pet>;

  subscriptions$: Observable<Subscription[]>;
  subscription$: Observable<Subscription>;

  constructor(billingPublicClient: BillingPublicClient,
              private dialog: MatDialog) {
    this.subscriptions$ = this.pet$.pipe(
      distinctUntilChanged((prev, cur) => prev.id == cur.id),
      switchMap(pet => billingPublicClient.getSubscriptions()
        .pipe(map(subscriptions => subscriptions.filter(s => !!s.pet && s.pet.id == pet.id)))),
      // switchMap(pet => billingPublicClient.getSubscriptions(new GetSubscriptions({customer_id: pet.owner.id, pet_id: pet.id}))),
      map(subscriptions => subscriptions.sort((prev, next) => next.start_date.unix() - prev.start_date.unix())),
      withBusy(() => this.busy),
      publishReplay(1),
      refCount()
    );

    this.subscription$ = this.subscriptions$.pipe(map(subscriptions => subscriptions.length ? subscriptions[0] : <Subscription>{}));
  }

  completion(subscription: Subscription): number {
    if (!subscription.current_period_start || !subscription.current_period_end) return 0;

    const today = !!subscription.ended_at ? subscription.ended_at.startOf('day') : moment().startOf('day');
    const start = subscription.current_period_start.startOf('day');
    const end = subscription.current_period_end.startOf('day');

    return Math.max(1, today.diff(start, 'day') * 100 / end.diff(start, 'day'));
  }

  async open(): Promise<void> {
    const subscriptions = await this.subscriptions$.pipe(first()).toPromise();
    this.dialog.open(SubscriptionsComponent, {data: subscriptions});
  }
}
