import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {FormControl, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {firstValueFrom, Observable} from "rxjs";
import {
  EditMode,
  SIDE_NAV_DATA,
  SideNavRef,
  PromoCode,
  SubscriptionsClient,
  UpsertPromoCode, CustomersClient, GetCustomers, PagedListOfCustomer
} from 'shared';
import {debounceTime, distinctUntilChanged, filter, map, switchMap} from "rxjs/operators";
import {Customer} from "../../../customers/public-clients";

@Component({
  selector: 'app-promo-code-form',
  templateUrl: './promo-code-form.component.html',
  styleUrls: ['./promo-code-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PromoCodeFormComponent {

  EditMode = EditMode;

  mode: EditMode;
  form: UntypedFormGroup;
  // customersControl = new FormControl();
  customers$: Observable<Customer[]>;

  constructor(
    private readonly sideNavRef: SideNavRef<PromoCodeFormComponent>,
    private formBuilder: UntypedFormBuilder,
    private subscriptionsClient: SubscriptionsClient,
    private customersClient: CustomersClient,
    @Inject(SIDE_NAV_DATA) public code?: PromoCode
  ) {
    this.mode = code ? EditMode.Edit : EditMode.Create;
    this.form = this.formBuilder.group({
      code: [code ? code.code : '', Validators.required],
      trial_days: [code ? code.trial_days.toString() : '0', [Validators.required, Validators.min(1)]],
      active: [code ? code.active : false],
      linked_to: [code ? code.linked_to : undefined],
      usage_limit: [code ? code.usage_limit.toString() : '0', [Validators.required, Validators.min(0)]],
      note: [code ? code.note : ''],
      valid_until: [code ? code.valid_until : null]
    });

    this.customers$ = this.form.get('linked_to').valueChanges
      .pipe(
        filter(c => typeof c === 'string'),
        debounceTime(400),
        distinctUntilChanged(),
        switchMap(val => this.customersClient.getCustomers(new GetCustomers({keywords: val}))),
        map(paged => paged.items)
      );
  }

  async save(): Promise<void> {
    if (!this.form.valid) return;

    const command = this.form.getRawValue();
    console.log(command.linked_to);
    try {
      await firstValueFrom(this.subscriptionsClient.upsertPromoCode(new UpsertPromoCode({
        ...command,
        linked_to: !!command.linked_to ? command.linked_to.id : null
      })))
      this.sideNavRef.close(true);
    } catch (error) {
      this.form.setErrors(error);
    }
  }

  displayFn(value?: Customer) {
    return value ? value.full_name : undefined;
    // return value ? this.filteredOptions.find(_ => _.id === value).name : undefined;
  }

  protected readonly Number = Number;
}
